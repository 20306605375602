<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :total-items="totalItems"
          :headers="headers"
          :items="students"
          :page="page"
          :search="search"
          :items-class="classes"
          :no-add-button="noAddButton"
          :filter-class="filterClass"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          title="Data Siswa"
          subtitle="Halaman untuk melihat seluruh total siswa "
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @filter-classes="handleFilterClass"
          @show-detail="showDetailStudent($event)"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="800"
      header="DATA LENGKAP SISWA"
      :hidden-actions="false"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template
        v-slot:body
      >
        <v-tabs
          v-model="tab"
          show-arrows
          fixed-tabs
        >
          <v-tab
            v-for="(tabItem, index) in tabs"
            :key="tabItem.title"
          >
            <span>{{ tabItem.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="pt-6 pb-0"
        >
          <v-tab-item :eager="true">
            <student-data-personal
              ref="studentDataPersonal"
              @close-dialog="modalDialog = false"
            ></student-data-personal>
          </v-tab-item>
          <v-tab-item
            :eager="
              true"
          >
            <student-data-school
              ref="studentDataSchool"
              @close-dialog="addFormDialog = false"
            ></student-data-school>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </ModalDialog>
    <ImageOverlay
      :visible="imageOverlay"
      :width="width"
      @close="imageOverlay = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeImage"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="student.image"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import ImageOverlay from '@/views/components/ImageOverlay.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import MainCard from '@/views/components/MainCard.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import StudentDataPersonal from './StudentDataPersonal.vue'
import StudentDataSchool from './StudentDataSchool.vue'

export default {
  name: 'StudentHeadMaster',
  components: {
    ImageOverlay,
    DataTablePagination,
    MainCard,
    ModalDialog,
    SkeletonLoaderTable,
    StudentDataPersonal,
    StudentDataSchool,
  },
  data() {
    return {
      icons: {
        mdiClose,
      },
      student: {
        image: null,
      },
      imageOverlay: false,
      width: 600,
      dialog: '',
      search: '',
      modalDialog: false,
      confirmDialog: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      noAddButton: true,
      filterClass: true,
      page: 1,
      totalPages: 1,
      totalItems: 1,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'NIS', value: 'nis' },
        { text: 'Nama Lengkap', value: 'name' },
        { text: 'Username', value: 'username' },
        { text: 'Jenis Kelamin', value: 'gender' },
        { text: 'Kelas', value: 'class[0].name' },
        { text: 'Foto', value: 'image' },
        { text: '', value: 'detail-student' },
      ],
      students: [],
      classes: [],
      class_uuid: '',
      tab: '',
      tabs: [{ title: 'Data Pribadi' }, { title: 'Data Sekolah' }],
      dataPerson: {},
    }
  },
  watch: {
    page: {
      handler() {
        this.listStudent()
      },
    },
  },
  beforeMount() {
    this.getSchoolUuid()
  },
  mounted() {
    this.listStudent()
    this.listClass()
  },

  methods: {
    handleFilterClass(uuid) {
      this.class_uuid = uuid ?? ''
      if (this.class_uuid !== null) return this.listStudent()

      return this.listStudent()
    },

    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }

        if (el.school === null) {
          return
        }
        this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
        this.school_uuid = el.school.uuid
      })

      return true
    },

    async listStudent(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('studentheadmaster', {
        ...params,
        school_uuid: this.school_uuid,
        class: this.class_uuid,
        page: this.page,
      }).then(
        ({ data }) => {
          this.students = data.data.map((students, index) => ({
            ...students,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
          this.isLoadingData = false
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    async showDetailStudent(uuid) {
      await this.$refs.studentDataPersonal.get(uuid)
      await this.$refs.studentDataSchool.get(uuid)
      this.modalDialog = true
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    async listClass() {
      await this.$services.ApiServices.list('class', { per_page: 'all', school_uuid: this.school_uuid }).then(
        ({ data }) => {
          this.classes = data.data
        },
        err => console.error(err),
      )
    },

    async searchHandler(data) {
      this.listStudent({ search: data })
    },
    async showImage(uuid) {
      await this.$services.ApiServices.get('greeting', uuid).then(
        ({ data }) => {
          this.student = data.data
          this.student.image = data.data.image
        },
        err => console.error(err),
      )
      this.imageOverlay = true
    },

    closeImage() {
      this.overlay = false
      this.imageOverlay = false
    },
  },
}
</script>

<style>
</style>
