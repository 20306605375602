<template>
  <div>
    <div class="d-flex justify-center mb-2">
      <div>
        <v-img
          width="150"
          src="@/assets/images/avatars/ari.png"
        ></v-img>
      </div>
    </div>
    <v-simple-table>
      <tbody>
        <tr
          v-for="data,index in datas"
          :key="index"
        >
          <td width="30%">
            {{ data.title }}
          </td>
          <td>
            <span class="me-4">: {{ data.content }} </span>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'StudentDataPersonal',
  data() {
    return {
      datas: [
        { title: 'NIS', keyName: 'nis', content: 'nis' },
        { title: 'NISN', keyName: 'nisn', content: 'nisn' },
        { title: 'Nama Lengkap', keyName: 'name', content: 'name' },
        { title: 'NIK', keyName: 'nik_ktp', content: 'nik_ktp' },
        { title: 'Jenis Kelamin', keyName: 'gender', content: 'gender' },
        { title: 'Agama', keyName: 'religion', content: 'religion' },
        { title: 'Etnis', keyName: 'ethnic_group', content: 'ethnic_group' },
        { title: 'Tanggal Lahir', keyName: 'date_of_birth', content: 'date_of_birth' },
        { title: 'Tempat Lahir', keyName: 'place_of_birth', content: 'place_of_birth' },
        { title: 'Tinggi Badan', keyName: 'height', content: 'height' },
        { title: 'Berat Badan', keyName: 'weight', content: 'weight' },
        { title: 'Golongan Darah', keyName: 'blood_type', content: 'blood_type' },
        { title: 'Hobi', keyName: 'hobby', content: 'hobby' },
        { title: 'Riwayat Penyakit', keyName: 'disease_history', content: 'disease_history' },
        { title: 'No Telpon Rumah', keyName: 'home_phone', content: 'home_phone' },
        // { title: 'Provinsi', keyName: 'ShiroHige' },
        // { title: 'Kabupaten', keyName: 'ShiroHige' },
        // { title: 'Kecamatan', keyName: 'ShiroHige' },
        // { title: 'Desa/Kelurahan', keyName: 'ShiroHige' },
        // { title: 'Alamat Lengkap', keyName: 'ShiroHige' },
        // { title: 'RT', keyName: 'ShiroHige' },
        // { title: 'RW', keyName: 'ShiroHige' },
      ],
      dataPersonals: [],
    }
  },
  methods: {
    async get(uuid) {
      await this.$services.ApiServices.get('student', uuid).then(({ data }) => {
        Object.keys(data.data).forEach((key, el) => {
          // console.log(el)
          this.datas.forEach(el => {
            console.log(el)
            if (el.keyName === key) {
              el.content = data.data[key]
            }
          })
        })
      })
    },
  },
}
</script>

<style>
</style>
